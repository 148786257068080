import { useRef, useState, useEffect } from "react";
import TagTextEditor from "./components/TagTextEditor.jsx";
import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { Error } from "components/lib.js";

const parseDataToSlateFormat = (data) => {
    const regex = /{([^}]+)}|\n/g;
    let result = [];
    let children = [];
    let lastIndex = 0;

    data.split('\n').forEach((line) => {
        let lineHasContent = false;

        line.replace(regex, (match, mention, index) => {
            lineHasContent = true;

            if (index > lastIndex) {
                children.push({ text: line.slice(lastIndex, index) });
            }

            if (mention) {
                children.push({ type: 'mention', character: mention, children: [{ text: '' }] });
                // Varmista, että mention-tyypin jälkeen on aina tyhjä tekstielementti
                if (!(index + match.length < line.length && line[index + match.length] === '')) {
                    children.push({ text: '' }); // Lisää tyhjä tekstielementti, jos sitä ei ole jo olemassa
                }
            }
            lastIndex = index + match.length;
        });

        if (lastIndex < line.length) {
            children.push({ text: line.slice(lastIndex) });
        }

        if (!lineHasContent) {
            // Ensure there's at least an empty text node
            children.push({ text: '' });
        }

        result.push({ type: 'paragraph', children: [...children] });
        children = [];
        lastIndex = 0;
    });

    return result;
}

const serializeSlateData = (nodes) => {
    return nodes.map(node => {
        return node.children.map(child => {
            if (child.type === 'mention') {
                return `{${child.character}}`;
            } else {
                return child.text;
            }
        }).join('') + '\n';
    }).join('').trim();
};

export const useTagTextEditor = (initialValue, tags) => {
    const [value, setValue] = useState(parseDataToSlateFormat(initialValue));
    const [addedTags, setAddedTags] = useState([]);
    const editorRef = useRef();

    // Effect to update the list of added tags whenever the editor value changes
    useEffect(() => {
        const currentTags = value
            .flatMap(node => node.children)
            .filter(child => child.type === 'mention')
            .map(mention => mention.character);
        setAddedTags(currentTags);
    }, [value]);

    /**
     * Inserts a tag into the editor at the current selection.
     * @param {string} tag - The tag to insert.
     */
    const insertTag = (tag) => {
        if (!editorRef.current) return;
        const editor = editorRef.current;

        const mention = {
            type: 'mention',
            character: tag,
            children: [{ text: '' }],
        };

        // Insert the mention node into the editor
        Transforms.insertNodes(editor, mention);
        Transforms.move(editor);

        // Focus the editor after inserting the tag
        ReactEditor.focus(editor);
    };

    /**
     * Checks if a given tag has already been added to the editor.
     * @param {string} tag - The tag to check.
     * @returns {boolean} - True if the tag is already added, false otherwise.
     */

    const isTagAdded = (tag) => addedTags.includes(tag);

    /**
     * Serializes the editor content into a string format.
     * Converts Slate nodes to a string with special formatting for mentions/tags.
     * @param {Array} nodes - The editor nodes to serialize.
     * @returns {string} - The serialized string representation of the content.
     */
    const serializeContent = (nodes) => {
        return nodes.map(node => {
            if (node.type === 'paragraph') {
                return node.children.map(child => {
                    if (child.text) {
                        return child.text;
                    }

                    if (child.type === 'mention') {
                        return `{${child.character}}`;
                    }

                    return '';
                }).join('');
            }

            return node.children.map(child => serializeContent([child])).join('\n');
        }).join('\n');
    }

    const element = (props) => {
        const error = props.errorMessage || 'Please enter a value';

        return (
            <div>
                <div className="relative block w-full p-3 rounded bg-white border border-solid border-gray-300 focus:bg-slate-50 appearance-none min-h-32">
                    <TagTextEditor
                        name={props.name}
                        editorRef={editorRef}
                        value={value}
                        setValue={setValue}
                        tags={tags}
                        onBlur={props?.onBlur}
                    />
                </div>
                {props.valid === false && <Error message={error} />}
            </div>
        );
    }

    return {
        element,
        insertTag,
        isTagAdded,
        serializedContent: serializeContent(value),
        serializedValue: serializeSlateData(value)
    }
}
