/***
*
*   SOCIAL SIGN IN BUTTONS
*   To sign up/in with Facebook, Google or Microsoft
*
**********/

import { useState } from 'react';
import { ClassHelper, Icon } from 'components/lib';
import Style from './social.tailwind.js';
import { Button } from "@material-tailwind/react";
import { Link } from 'react-router-dom';

export function SocialSignin(props) {

  const [loading, setLoading] = useState(props.network?.map(x => { return { [x]: false } }));
  const serverURL = process.env.REACT_APP_SERVER_URL;

  // construct query string
  let qs = '';
  if (props.invite) qs = `?invite=${props.invite}`;
  if (props.signup) {
    qs = '?signup=1'
  }


  return (
    <div className={Style.signinButtons}>
      {props.network?.map(n => {

        const css = ClassHelper(Style, { [n]: true, signinButton: true });

        return (
          <a href={`${serverURL}/auth/${n}${qs}`}>
            <Button
              size="lg"
              variant="outlined"
              color='gray'
              className="flex items-center justify-center gap-3 w-full "
              loading={loading[n]}
            >
              <Icon
                image={['fab', n]}
                pack={'fontawesome'}
              />
              {`Continue with ${n.charAt(0).toUpperCase() + n.slice(1)}`}
            </Button>
          </a>
        )
      })}

      {props.showOr &&
        <span className={Style.or}>or</span>}

    </div>
  );
}
