import {TagElement} from "./TagElement.jsx";
import PropTypes from "prop-types";

export const Element = ({ attributes, children, element, editorRef }) => {
    switch (element.type) {
        case 'mention':
            return <TagElement {...{attributes, children, element, editorRef}}/>
        default:
            return <p {...attributes}>{children}</p>
    }
}

Element.propTypes = {
    attributes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    element: PropTypes.object.isRequired,
    editorRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ]).isRequired,
};
