import { useEffect, useCallback, useMemo } from "react";
import { createEditor, Transforms } from "slate";
import { withHistory } from "slate-history";
import { Slate, Editable, withReact } from 'slate-react';
import PropTypes from "prop-types";
import { Element } from "./Element.jsx";

/**
 * TagTextEditor is a customizable rich text editor component built using Slate.js.
 * It's designed to handle text input with special tagging features. The component integrates
 * with the Slate editor, enhancing it with custom tagging capabilities where users can insert
 * predefined tags into the text. It also handles the rendering of different types of nodes and leaves,
 * and manages the custom behavior of the 'mention' type nodes, representing tags.
 */
const TagTextEditor = ({ editorRef, value, setValue, tags, ...props }) => {
    const renderElement = useCallback(props => <Element {...props} editorRef={editorRef} />, [editorRef]);
    const editor = useMemo(
        () => withMentions(withReact(withHistory(createEditor()))),
        []
    );

    useEffect(() => {
        if (value) {
            editorRef.current = editor;
        }
    }, [editor, editorRef, value]);

    /**
     * Deserializes plain text into Slate nodes, specifically handling the extraction and omission of tags.
     * @param {string} text - The plain text to deserialize.
     * @returns {Array} - An array of Slate nodes.
     */
    const deserialize = (text) => {
        return text.split('\n').map(line => {
            const tagRegex = new RegExp(tags.map(tag => `(${tag}\\s*x)`).join('|'), 'gi');

            const withoutMentions = line.replace(tagRegex, '');

            return {
                type: 'paragraph',
                children: [{ text: withoutMentions }],
            };
        });
    };

    /**
     * Custom paste handler that intercepts paste events, processes the pasted content,
     * and inserts it as Slate nodes into the editor.
     * @param {Event} event - The paste event.
     */
    const handlePaste = useCallback((event) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text/plain');
        const pastedNodes = deserialize(pastedText);

        if (editorRef.current) {
            Transforms.insertFragment(editorRef.current, pastedNodes);
        }
    }, [editorRef]);

    if (!value) return;

    return (
        <Slate
            editor={editor}
            initialValue={value}
            value={value}
            onChange={newValue => setValue(newValue)}
        >
            <Editable
                style={{
                    minHeight: "160px",
                    maxHeight: "348px",
                    overflowY: "auto",
                    outline: 'none',
                }}
                onPaste={handlePaste}
                disabled={true}
                renderElement={renderElement}
            />
        </Slate>
    )
}

/**
 * Enhances the Slate editor with custom handling for 'mention' type nodes.
 * Defines the behavior of these nodes as inline and void.
 * @param {Editor} editor - The Slate editor instance.
 * @returns {Editor} - The enhanced editor.
 */
const withMentions = editor => {
    const { isInline, isVoid } = editor;

    editor.isInline = element => {
        return element.type === 'mention' ? true : isInline(element);
    };

    editor.isVoid = element => {
        return element.type === 'mention' ? true : isVoid(element);
    };

    return editor;
}

TagTextEditor.propTypes = {
    editorRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ]),
    value: PropTypes.array,
    setValue: PropTypes.func
};

export default TagTextEditor;
